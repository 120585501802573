<template>
	<div>
		<PrepaidPaymentProcessing
			v-if="prepaidPaymentProcessingModal"
			@update:modelValue="prepaidPaymentProcessingModal = !prepaidPaymentProcessingModal"
		/>
		<SelfServiceWizardStep v-else>
			<template #headline>
				<h2>Lad kontantkort</h2>
			</template>
			<template #main>
				<UiMessage
					v-if="error"
					:error="error"
					title="Noe gikk galt da vi prøvde å lade kontantkortet"
					type="error"
					message="Vennligst prøv igjen senere. Kontakt kundeservice dersom problemet vedvarer."
				></UiMessage>
				<TnTextField
					v-model="msisdn"
					v-if="!$route.query.msisdn"
					:disabled="loading"
					class="margin-bottom-l"
					label="Mobilnummeret du vil lade"
				>
					<template
						v-if="showSubscriptionIsPostPaidError"
						#error
					>
						{{ `${msisdn} er ikke et kontantkort-abonnement.` }}
					</template>
				</TnTextField>
				<TnDropdownSelect
					v-model="chargeValue"
					:options="options"
					label="Velg ladebeløp"
					class="margin-bottom-l"
					:disabled="loading"
				/>
				<TnTextField
					v-if="chargeValue === 'CUSTOM'"
					v-model="customChargeValue"
					label="Angi ønsket beløp"
					@focus="isEditingCustomChargeValue = true"
					@blur="isEditingCustomChargeValue = false"
				>
					<template
						v-if="showCustomChargeValueError"
						#error
					>
						Verdi må være mellom 25 og 1000
					</template>
				</TnTextField>
				<TnButton
					v-if="!createRegards"
					class="margin-bottom-l"
					tertiary
					size="xs"
					@click="createRegards = true"
				>
					Skriv en hilsen
				</TnButton>
				<TnTextArea
					v-model="regardsText"
					v-if="createRegards"
					label="Din hilsen"
				>
					<template
						v-if="showRegardsErrorText"
						#error
					>
						{{ showRegardsErrorText }}
					</template>
				</TnTextArea>
				<TnRadiobuttonGroup
					v-model="paymentMethod"
					:options="paymentMethods"
					size="m"
					label="Velg betalingsmåte"
					:disabled="loading"
				/>
			</template>
			<template #actions>
				<TnButton
					class="margin-top-xl"
					:disabled="!canProceed || loading"
					:loading="loading"
					@click="validateAndPay"
				>
					Betal og lad
				</TnButton>
			</template>
		</SelfServiceWizardStep>
	</div>
</template>

<script>
import { fetchPaymentUrlForNotLoggedIn } from "~/integrations/nets-payment";
import { v4 as uuid } from "uuid";
import prepaidTopUpIntegration from "~/integrations/prepaid-top-up-integration";

export default defineNuxtComponent({
	name: "ChargePrepaid",

	data() {
		return {
			options: [
				{
					value: "200",
					label: "200 kr",
				},
				{
					value: "400",
					label: "400 kr",
				},
				{
					value: "1000",
					label: "1000kr",
				},
				{
					value: "CUSTOM",
					label: "Egendefinert beløp",
				},
			],
			paymentMethods: [
				{
					value: "CARD",
					label: "Kort",
				},
				{
					value: "VIPPS",
					label: "Vipps",
				},
			],
			msisdn: useRoute().query.msisdn,
			chargeValue: "0",
			customChargeValue: "",
			isEditingCustomChargeValue: true,
			paymentMethod: "",
			loading: false,
			createRegards: false,
			regardsText: "",
			error: null,
			showSubscriptionIsPostPaidError: false,
			prepaidPaymentProcessingModal: !!useRoute().query.prepaidStatus,
		};
	},

	computed: {
		canProceed() {
			const hasValidPaymentMethod = this.paymentMethods.find((p) => p.value === this.paymentMethod);
			if (!this.msisdn) return false;
			return (
				(this.hasValidPresetChargeValue || this.hasValidCustomChargeValue) &&
				hasValidPaymentMethod &&
				(this.createRegards ? this.hasValidRegardsText : true)
			);
		},
		hasValidPresetChargeValue() {
			const regex = "^((1000)|([1-9][0-9]{2})|([3-9][0-9]{1})|(2[5-9]))$"; // Checking for digits between 25 and 1000
			return RegExp(regex).test(this.chargeValue);
		},
		hasValidCustomChargeValue() {
			const regex = "^((1000)|([1-9][0-9]{2})|([3-9][0-9]{1})|(2[5-9]))$"; // Checking for digits between 25 and 1000
			return RegExp(regex).test(this.customChargeValue);
		},
		hasValidRegardsText() {
			const regex = "^[\\w\\d\\s.,øæå-]{0,70}$"; // Checking for only letters, numbers, "." and ",", and max 80 characters.
			return RegExp(regex).test(this.regardsText);
		},
		showCustomChargeValueError() {
			return (
				(!this.isEditingCustomChargeValue && !this.hasValidCustomChargeValue) ||
				(this.customChargeValue.length >= 3 && !this.hasValidCustomChargeValue)
			);
		},
		showRegardsErrorText() {
			if (!this.createRegards) {
				return false;
			} else if (!this.hasValidRegardsText) {
				if (this.regardsText.length >= 70) {
					return "Maks 70 tegn er tillatt";
				}
				return "Kun bokstaver, tall, komma og punktum er tillatt";
			}

			return false;
		},
	},

	watch: {
		msisdn() {
			this.showSubscriptionIsPostPaidError = false;
		},
	},

	methods: {
		async validateAndPay() {
			try {
				this.loading = true;
				const res = await prepaidTopUpIntegration.numberIsPrepaid(this.msisdn);
				if ((res.data && res.data.isPrepaid) || res.isPrepaid) {
					await this.redirectToPayment();
				} else {
					this.showSubscriptionIsPostPaidError = true;
					this.loading = false;
				}
			} catch (e) {
				this.error = e;
				this.loading = false;
			}
		},
		async redirectToPayment() {
			let msisdn = this.msisdn;

			if (msisdn.length < 10) {
				msisdn = "47" + msisdn;
			}

			const phone = msisdn.slice(2);

			const orderReference = `MTW${phone}${uuid().replace(/-/g, "").substring(0, 18)}`;

			let successUrl;
			let cancelUrl;

			if (useRoute().fullPath.indexOf("?") > -1) {
				successUrl = `${useRoute().fullPath}&prepaidStatus=payment_succeeded`;
				cancelUrl = `${useRoute().fullPath}&prepaidStatus=payment_failed`;
			} else {
				successUrl = `${useRoute().fullPath}?prepaidStatus=payment_succeeded`;
				cancelUrl = `${useRoute().fullPath}?prepaidStatus=payment_failed`;
			}

			try {
				const { paymentRedirectUri } = await fetchPaymentUrlForNotLoggedIn({
					purchaseDescription: "Lading av kontantkort",
					orderReferenceId: orderReference,
					amount: `NOK ${this.chargeValue === "CUSTOM" ? this.customChargeValue : this.chargeValue}`,
					successUrl,
					cancelUrl,
					account: "PREPAID_RECHARGE",
					paymentMethod: this.paymentMethod,
					transactionType: "AUTH_CAPTURE",
					phoneNumber: `+${msisdn}`,
					purchaseData: JSON.stringify({
						regardsText: this.regardsText,
						phoneInput: phone,
					}),
				});
				window.location.href = paymentRedirectUri;
			} catch (err) {
				this.error = err;
				this.loading = false; /* This is intentionally NOT placed in a "finally"-block.
      If successful, the user will be redirected, and this might take a few seconds.
      It's better to show a loading animation during this time */
			}
		},
	},
});
</script>
